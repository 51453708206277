/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Library
.bg-lillac {
  background-color: var(--primary-color);
}

.bg-beige {
  background-color: #E9E4DC;
}

.place-self-flex-end {
  place-self: flex-end;
}

.justify-center {
  justify-content: center !important;
}
.flex-1{
  flex: 1 !important;
}
.align-center{
  align-items: center;
}
.align-self-center{
  align-self: center;
}
.align-start{
  align-items: flex-start;
}
.align-end{
  align-items: flex-end;
}
.place-baseline {
  place-content: baseline;
}
.place-start {
  place-content: flex-start !important;
}
.place-end {
  place-content: flex-end !important;
}
.place-center {
  place-content: center;
}
.place-self-center {
  place-self: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.dflex {
  display: flex;
}

.pb-2 {
  padding-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.place-center {
  place-content: center !important;
}

.align-center {
  align-items: center;
}

.btn-round {
  padding: 10px 20px !important;
  min-width: 150px !important;
  border-radius: 3em !important;
  // color: #fff !important;
}

mat-label {
  font-family: 'Brother1816';
  place-content: center;
  width: 100%;
  display: flex;
  color: #5e5e5e;
  margin-bottom: 10px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background: #fff;
  border-radius: 0.5em;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--primary-color) !important;
}

.inline-calendar {
  max-width: 400px;
  background: #fff;
}

.content-center {
  align-items: center;
  place-content: center;
}
//Screens
.intro {
  .swiper-container-horizontal>.swiper-pagination-bullets {
    top: 40px;
    bottom: auto;
    left: 40px;
    text-align: left;
    width: auto;

    .swiper-pagination-bullet:nth-child(even) {
      // .swiper-pagination-bullet-active{
      background: #E9E4DC;
      // }
    }
  }
}

.tab-container {
  display: flex;
  width: 100%;
  overflow: auto;
  place-content: center;

  ul {
    list-style: none;
    display: flex;
    // background: var(--primary-color, 0.8);
    color: #5E5E5E;
    background: #EDEDEB;
    padding: 0px;
    border-radius: 30px;
    font-family: 'Brother1816';
    margin: 0px;

    @media (max-width:767px) {
      background: transparent;
      flex-direction: column;
    }
    li {
      // color: #FFF;
      // text-transform:;
      padding: 20px 40px;
      cursor: pointer;

      white-space: nowrap;
      place-content: center;
      align-items: center;
      display: flex;

      &.active {
        border-radius: 30px;
        background: var(--primary-color);
        color: #fff;
      }

      @media (max-width:767px) {
        font-size: 14px;
        padding: 20px 25px;
        color: #5E5E5E;
        background: #EDEDEB;
        border-radius: 30px;
        margin: 10px 0px;
      }
    }
  }
}



.chapter-card {
  margin: 20px 0px !important;
  border-radius: 30px !important;
  min-height: 80px;
  background: #F5F5F5;
  font-family: 'Brother1816';
  border: 1px solid #70707021;
  padding: 10px 0px !important;

  .mat-expansion-panel-header {
    height: auto;
    min-height: 80px !important;
    align-items: start;
    padding: 0px;
    .mat-content {
      place-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 0px;
      .mat-expansion-panel-header-title {
        display: flex;
        flex-grow: 1;
        margin-right: 16px;
        font-family: 'Brother1816';
        text-transform: uppercase;
        color: #5E5E5E;
        font-size: 15px;
        padding: 12px 24px !important;
        margin: 16px;
        place-content: center;
        border-bottom: 1px solid #70707021;
        // margin-left: 56px;
        width: 100%;
      }

      .mat-expansion-panel-header-description {
        flex-grow: 0 !important;
        margin-bottom: 16px;
        margin-right: 16px;
        margin-left: 16px;
        margin-top: 16px;
        padding: 0px 24px !important;
        font-family: 'Brother1816';
        color: rgba(94, 94, 94, 0.54) !important;

        .mat-icon {
          width: 32px;
          height: 32px;
        }
      }
    }

    &.centered {
      .mat-content {
        .mat-expansion-panel-header-title {
          margin-left: 16px !important;
        }

        .mat-expansion-panel-header-description {
          margin-left: 16px !important;
          width: 100%;
        }
      }
    }

    .mat-expansion-indicator {
      margin-right: 16px;
      margin-top: 16px;
      width: 24px;
      text-align: center;

      &::after {
        border-width: 0 1px 1px 0 !important;
        padding: 7px !important;
      }
    }
  }

  &.content-card {
    min-height: auto;

    .mat-expansion-panel-header {
      min-height: auto !important;

      &.locked {
        .mat-content {
          flex-direction: row;

          .mat-expansion-panel-header-title {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 20px 0px 0px 0px;

      .module-list {
        padding-bottom: 16px;

        .module-name {
          border-top: 1px solid #70707021;
          border-bottom: 0.1px solid #70707021;
          place-content: center;
          font-family: 'Brother1816' !important;
          text-transform: initial;
          min-height: 60px !important;
          height: auto;
          opacity: 1;
          padding: 16px 24px;
          line-height: 24px;
          color: #5E5E5E;
        }

        .content-card {
          height: auto;
          min-height: 48px;
          padding: 16px 0px;
          padding-bottom: 24px;

          .content-title {
            font-family: 'Brother1816';
            opacity: 1;
            white-space: normal;

            &.link {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          .mat-list-item-content {
            flex-direction: row-reverse !important;
            padding-right: 16px;
            opacity: 0.8;

            .mat-icon {
              margin: 0px 16px;
              width: 32px;
              height: 32px;
              font-size: 32px;
            }

            .mat-list-text {
              padding-right: 16px !important;
            }
          }
        }
      }
    }
  }
}


.list-box {
  display: flex;
  width: 100%;
  height: auto;

  &.program-card {
    margin: 40px 0px;
    padding: 60px 80px;
    // max-width: 450px;
  }

  .box {
      display: flex;
      flex-direction: column;

      h1 {
          font-family: 'CormorantGaramond';
          font-weight: 800;
          font-size: 46px;
          line-height: 56px;
          color: #5E5E5E;
      }

      p {
          font-size: 16px !important;
          font-family: 'Brother1816';
          font-weight: 400;
          margin-top: 12px;
          color: #5E5E5E;
          // opacity: 0.7;
      }

      .action-button {
          display: flex;
          // place-self: flex-end;
      }
  }

}
.contemplation-space {
    background: #E9E4DC;
}

.beige-space {
    background: #E9E4DC;
}

.grey-space {
    background: #F5F5F5;
}

.white-space {
    background: #FFFFFF;
}

@media (hover: none) {
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: transparent
}
}


.modal-content,
.mat-dialog-container,
.modal-default {
    // border-radius: 25px;
    border-radius: 0px;
    .box {
        position: relative;
        top: 0;
        opacity: 1;
        display: flex;
        align-items: left;
        place-content: flex-start;
        width: 100%;
        transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        z-index: 5;
        height: 99.98vh;
        overflow: hidden;
        flex-direction: column;
        border-radius: 0px;
        .back {
            display: inline-flex;
            place-content: center;
            align-items: center;
            font-size: 15px;
            color: #888;
            font-weight: 300;
            cursor: pointer;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;

            &:hover {
                color: var(--primary-color);
            }

            mat-icon {
                font-size: 20px;
                text-align: center;
                place-content: center;
                align-items: center;
                display: inline-flex;
            }
        }

        .title {
            // font-size: 20px;
            // text-align: left;
            // font-weight: 600;
            // padding-bottom: 20px;
            border-bottom: 1px solid #dee2e6;
            padding: 1.5rem;
            margin: 0px;
            padding-right: 3rem;
            color: rgb(149, 124, 105) !important;
            border-radius: 25px 25px 0px 0px;
            flex: 0;
            line-height: 32px;
            font-family: 'CormorantGaramond';
            mat-icon {
                display: inline-block;
                position: relative;
                top: 4px;
                margin-left: 16px;
            }
            p {
                margin-top: 8px;
                font-size: 16px;
                color: #5E5E5E;
                line-height: 21px;
            }
        }


        .content-section{
            overflow-y: auto;


            .action-button {
                flex: 0;
                padding: 0rem 2rem;
            }
        }
        .login-form {
            padding: 2rem;
            text-align: center;
            overflow-y: auto;
            flex: 1;
        }

        .action-button {
            flex: 0;
            padding: 0rem 2rem;
        }
    }
}


.mat-dialog-container {
    border-radius: 25px !important;

    .modal-container {
        margin: -1.5rem;

        .title {
            padding-right: 3.5rem;
        }
    }
}


@media (max-width:767px) {
  .modal-default,
  .modal-dialog{
    margin: 0px;

    .modal-content,
    .mat-dialog-container {
      .box {
        height: 99.75vh;
        border-radius: 0px;
        .title {
          border-radius: 0px
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
.modal-default {
  --width: 100%;
  --height: 100%;
}
}

.cursorpointer{
  cursor: pointer;
}

.overflow-hidden{
  overflow: hidden;
}.mat-tooltip {
  background: rgb(149 180 181);
}

button {
  min-height: 50px;    
  font-family: 'Brother1816';
  font-size: 14px;
}
.ion-page {
  background: #E9E4DC;
  padding-top: env(safe-area-inset-top);
}
.w-0 {
  width: 0px;
}
// .modal-default {
//   padding-top: env(safe-area-inset-top);
// }

.place-space-between{
  place-content: space-between;
}

.round-confirm-box {
  background: transparent;
  border-radius: 24px;
  overflow: hidden;
}


.mat-mdc-dialog-surface {
  border-radius: 24px !important;
  background: trans;
}


.swiper-pagination-progressbar-fill {
  background: #a8c1c1 !important;
}

.swiper-pagination-bullet-2 {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-2.swiper-pagination-bullet-active {
  color: #fff;
  background: #a8c1c1;
}

.page-bullet {
  padding: 4px 8px;
}
.btn-default {
  background: #B2ADA6;
  color: white;
  font-family: 'Brother1816';
  // font-weight: bold;
  &:hover {
    border: 1px solid #B2ADA6;
    color: #B2ADA6;
    background: #fff;
  }
}
.border-solid-1 {
  border: 1px solid #000;
}

.inline-button {
    place-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    margin-left: 12px;
    height: 100%;
    mat-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
    }
}
.badge {
    font-weight: normal;
}
.mat-card-header-text {
  margin: 0px !important;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #B2ADA6;
  border-radius: 4px;
  transition: background 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e978f;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #B2ADA6 #f1f1f1;
}
