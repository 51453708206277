// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;


@font-face {   
  font-family: 'Brother1816';    //This is what we are going to call
  src: url('../assets/fonts/Brother1816-Regular.ttf');
}
@font-face {   
  font-family: 'CormorantGaramond';  //This is what we are going to call  
  src: url('../assets/fonts/CormorantGaramond-Bold.ttf');
}
@font-face {   
  font-family: 'HalyardDisplayBook';  //This is what we are going to call  
  src: url('../assets/fonts/Halyard-Display-Book.ttf');
}

// Plus imports for other components in your app.
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'CormorantGaramond', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'CormorantGaramond', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'CormorantGaramond', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'CormorantGaramond', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'CormorantGaramond', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'CormorantGaramond', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Brother1816', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'CormorantGaramond', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'HalyardDisplayBook', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'HalyardDisplayBook', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Brother1816', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Brother1816', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Brother1816', 1.5px)
);
// Foreground Elements

// Light Theme Text
$dark-text: #5e5e5e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #5e5e5e;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #e9e5e5;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#bcc6c1, 20%);
$dark-bg-alpha-4: rgba(#bcc6c1, 0.04);
$dark-bg-alpha-12: rgba(#bcc6c1, 0.12);

$mat-light-theme-background: (background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #bcc6c1;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#e9e5e5, 0.04);
$light-bg-alpha-12: rgba(#e9e5e5, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.legacy-core();

// Theme Config

body {
    --primary-color: #95b4b5;
    --primary-lighter-color: #dfe9e9;
    --primary-darker-color: #789c9d;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$dark-primary-text};
    --swiper-pagination-bullet-width: 'auto';
    --swiper-pagination-bullet-border-radius: 4px;
    --swiper-pagination-bullet-height: 'auto';
    --swiper-pagination-color: #a8c1c1;
}

$mat-primary: (main: #95b4b5,
    lighter: #dfe9e9,
    darker: #789c9d,
    200: #95b4b5, // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ));
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
    --accent-color: #e9e4dc;
    --accent-lighter-color: #f8f7f5;
    --accent-darker-color: #dfd9ce;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (main: #e9e4dc,
    lighter: #f8f7f5,
    darker: #dfd9ce,
    200: #e9e4dc, // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ));
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: (primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
);
$altTheme: (primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-legacy-component-themes($theme);

.theme-alternate {
    @include mat.all-legacy-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming
button, [type=button], [type=reset], [type=submit] {
    background: none;
    display: flex;
    border: none;
    box-shadow: none;
    flex-direction: row;
    place-items: center;
    align-items: center;
    -webkit-appearance: none !important;
}


// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons Outlined', 'Material Icons';

    .mat-badge-content {
        font-family: 'Roboto';
    }
}

$white: #ffffff;

$theme-colors: (
    "light":      #f5f5f5,
    "dark":       #5e5e5e,
    "primary":    #95b4b5,
    "secondary":  #e9e4dc,
    "info":       #8282d1,
    "success":    #49cb84,
    "warning":    #ecd73c,
    "danger":     #f9392e,
);
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'BROTHER1816';
	--ion-color-primary: #95b4b5;
	--ion-color-primary-rgb: 149,180,181;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #839e9f;
	--ion-color-primary-tint: #a0bcbc;

	--ion-color-secondary: #e9e4dc;
	--ion-color-secondary-rgb: 233,228,220;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #cdc9c2;
	--ion-color-secondary-tint: #ebe7e0;

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82,96,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #5E5E5E;
	--ion-color-medium-rgb: 94,94,94;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #535353;
	--ion-color-medium-tint: #6e6e6e;

	--ion-color-light: #FFFFFF;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

html, body { height: 100%; }
body { margin: 0; font-family: 'HalyardDisplayBook'; }

// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// /* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

.swiper-pagination {
    .swiper-pagination-bullet {
        padding: 4px 8px;
    }
}